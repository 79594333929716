import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import TEMPLATES from './templates/templates';

const USE_LOCAL_STORAGE = true;

export interface EducationUnit {
    id: string;
    degree: string;
    school: string;
    fieldOfStudy: string;
    start: Date | null;
    end: Date | string | null;
}

export interface WorkUnit {
    id: string;
    jobTitle: string;
    employer: string;
    location: string;
    start: Date | null;
    end: Date | string | null;
    details: string;
}

export interface SkillUnit {
    id: string;
    skill: string;
    level: string;
}

export interface Certificate {
    id: string;
    certificate: string;
    description: string;
}

export interface LanguageUnit {
    id: string;
    language: string;
    level: string;
}

export interface UserContextType {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
    town: string;
    address: string;
    introInformation: string;
    educations: EducationUnit[];
    works: WorkUnit[];
    skills: SkillUnit[];
    certificates: Certificate[];
    templateId: number;
    imageSrc: string | null;
    previewOpen: boolean;
    linkedin: string;
    git: string;
    website: string;
    languages: LanguageUnit[];
    desiredPosition: string;
    setFirstName: (v: string) => void;
    setLastName: (v: string) => void;
    setEmail: (v: string) => void;
    setPhone: (v: string) => void;
    setCountry: (v: string) => void;
    setTown: (v: string) => void;
    setAddress: (v: string) => void;
    setImageSrc: (v: string | null) => void;
    setIntroInformation: (v: string) => void;
    setEducations: (v: EducationUnit[]) => void;
    setWorks: (v: WorkUnit[]) => void;
    setSkills: (v: SkillUnit[]) => void;
    setTemplateId: (v: number) => void;
    downloadCV: () => void;
    getPDFBlob: () => Promise<string>;
    setPreviewOpen: (v: boolean) => void;
    setLinkedin: (v: string) => void;
    setGit: (v: string) => void;
    setWebsite: (v: string) => void;
    setLanguages: (v: LanguageUnit[]) => void;
    setDesiredPosition: (v: string) => void;
    setCertificates: (v: Certificate[]) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export const UserProvider = ({ children }: PropsWithChildren) => {
    const [firstName, setFirstName] = UseLocalStorage<string>('firstName', '');
    const [lastName, setLastName] = UseLocalStorage<string>('lastName', '');
    const [email, setEmail] = UseLocalStorage<string>('email', '');
    const [phone, setPhone] = UseLocalStorage<string>('phone', '');
    const [country, setCountry] = UseLocalStorage<string>('country', '');
    const [town, setTown] = UseLocalStorage<string>('town', '');
    const [address, setAddress] = UseLocalStorage<string>('address', '');
    const [introInformation, setIntroInformation] = UseLocalStorage<string>('introInformation', '');
    const [educations, setEducations] = UseLocalStorage<EducationUnit[]>('educations', []);
    const [works, setWorks] = UseLocalStorage<WorkUnit[]>('works', []);
    const [templateId, setTemplateId] = UseLocalStorage<number>('templateId', 0);
    const [skills, setSkills] = UseLocalStorage<SkillUnit[]>('skills', []);
    const [imageSrc, setImageSrc] = UseLocalStorage<string | null>('imageSrc', null);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [linkedin, setLinkedin] = UseLocalStorage<string>('linkedin', '');
    const [git, setGit] = UseLocalStorage<string>('git', '');
    const [website, setWebsite] = UseLocalStorage<string>('website', '');
    const [languages, setLanguages] = UseLocalStorage<LanguageUnit[]>('language', []);
    const [desiredPosition, setDesiredPosition] = UseLocalStorage<string>('desiredPosition', '');
    const [certificates, setCertificates] = UseLocalStorage<Certificate[]>('certificates', []);

    const getBlob = async () => {
        const Template = TEMPLATES.filter(t => t.id === templateId)[0]?.type;

        return await pdf(<Template value={{
            firstName,
            lastName,
            email,
            phone,
            country,
            town,
            address,
            introInformation,
            educations,
            works,
            skills,
            templateId,
            imageSrc,
            previewOpen,
            linkedin,
            git,
            website,
            languages,
            desiredPosition,
            certificates,
            setFirstName,
            setLastName,
            setEmail,
            setPhone,
            setCountry,
            setTown,
            setAddress,
            setIntroInformation,
            setEducations,
            setWorks,
            setSkills,
            setImageSrc,
            setTemplateId,
            downloadCV,
            getPDFBlob,
            setPreviewOpen,
            setLinkedin,
            setGit,
            setWebsite,
            setLanguages,
            setDesiredPosition,
            setCertificates,
        }} />).toBlob();
    }

    const downloadCV = async () => {
        const blob = await getBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'cv.pdf';
        document.body.appendChild(link);
        link.click();
    }

    const getPDFBlob = async () => {
        const blob = await getBlob();
        return URL.createObjectURL(blob);
    }

    return (
        <UserContext.Provider value={{
            firstName,
            lastName,
            email,
            phone,
            country,
            town,
            address,
            introInformation,
            educations,
            works,
            skills,
            imageSrc,
            templateId,
            previewOpen,
            linkedin,
            git,
            website,
            languages,
            desiredPosition,
            certificates,
            setFirstName,
            setLastName,
            setEmail,
            setPhone,
            setCountry,
            setTown,
            setAddress,
            setIntroInformation,
            setEducations,
            setWorks,
            setSkills,
            setTemplateId,
            setImageSrc,
            downloadCV,
            getPDFBlob,
            setPreviewOpen,
            setLinkedin,
            setGit,
            setWebsite,
            setLanguages,
            setDesiredPosition,
            setCertificates
        }}>
            {children}
        </UserContext.Provider>
    );
};


function UseLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    // Try to retrieve the value from local storage, if it fails use initial value
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = USE_LOCAL_STORAGE ? window.localStorage.getItem(key) : undefined;

            if (item) {
                const result = JSON.parse(item);
                if (key === "educations" || key === "works") {
                    for (let r of result) {
                        if (r.start) {
                            r.start = new Date(Date.parse(r.start));
                        }
                        if (r.end) {
                            if (r.end === "Now") {

                            } else {
                                r.end = new Date(Date.parse(r.end));
                            }
                        }
                    }
                }
                return result;
            } else {
                return initialValue;
            }
        } catch (error) {
            console.error('Error retrieving data from local storage:', error);
            return initialValue;
        }
    });

    // Update the value in local storage whenever it changes
    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            if (USE_LOCAL_STORAGE) {
                window.localStorage.setItem(key, JSON.stringify(value));
            }
        } catch (error) {
            console.error('Error saving data to local storage:', error);
        }
    };

    return [storedValue, setValue];
}