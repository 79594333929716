import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { CiLinkedin } from "react-icons/ci";
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconLink, IconLinkedin, IconLocationDot } from '../icons';

Font.register({ family: 'Roboto', src: '/fonts/Roboto/Roboto-Regular.ttf' });
Font.register({ family: 'RobotoBold', src: '/fonts/Roboto/Roboto-Bold.ttf' });
Font.register({ family: 'RobotoMedium', src: '/fonts/Roboto/Roboto-Medium.ttf' });

const color = '#000000';
const border = '1px solid #888888';

const mainTextColor = '#333333';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 11,
        color: mainTextColor,
        width: '100%'
    },
    section: {
        marginBottom: 8,
        paddingBottom: 6,
        display: 'flex',
        flexDirection: 'column',
        borderBottom: border,
        width: '100%'
    },
    sectionNoBorder: {
        marginBottom: 8,
        paddingBottom: 6,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        marginBottom: 8,
        paddingBottom: 6,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%'
    },
    topSectionContent: {
    },
    sectionTitle: {
        fontSize: '14px',
        color: '#888888',
        fontFamily: 'RobotoMedium',
        marginBottom: 10
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '72%',
        wordWrap: 'break-word'
    },
    sidebar: {
        width: '24%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    name: {
        fontSize: '20px',
        color: color,
        fontFamily: 'RobotoMedium',
        textAlign: 'center'
    },
    email: {
        fontSize: '11px',
        textAlign: 'center'
    },
    phone: {
        fontSize: '11px',
        textAlign: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        display: 'flex',
        width: '100%',
        paddingBottom: 20,
        gap: 3
    },
    image: {
        width: '60px',
        height: 'auto'
    },
    title: {
        color: color,
        fontSize: 13
    },
    emphasize: {
        fontSize: 11,
        fontFamily: 'RobotoMedium',
        color: color,
    },
    date: {
        color: mainTextColor,
        fontFamily: 'Roboto',
        fontSize: 9
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    link: {
        color: mainTextColor,
        fontSize: 9,
        textDecoration: 'none',
    },
    contactsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        paddingTop: 6,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'column',
    },
    skill: {
        fontSize: '13px',
        color: mainTextColor,
        padding: 2,
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    language: {
        fontSize: '13px',
        color: mainTextColor,
        padding: 2,
    },
    languageLevel: {

    },
    personal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingBottom: 8,
        gap: 6
    },
    logo: {
        position: 'absolute',
        bottom: 1,
        right: 1,
        fontSize: 8
    }
});

// Simple
const Template002 = ({ value }: { value: UserContextType }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        skills,
        languages,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
    } = value;
    const formattedWorks = works.map(work => {
        const start = new Date(work.start as any);
        const end = new Date(work.end as any);
        return <View style={styles.work}>

            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.title}>{work.jobTitle}</Text>
                    <Text style={styles.emphasize}>{work.employer}</Text>
                </View>
                <Text style={styles.date}>{start.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                })} - {end.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                })}</Text>
            </View>

            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        const start = new Date(educations.start as any);
        const end = new Date(educations.end as any);
        return <View key={educations.id} style={styles.work}>
            <View style={styles.col}>
                <View style={styles.col}>
                    <Text style={styles.title}>{educations.degree} in {educations.fieldOfStudy}</Text>
                    <Text style={styles.emphasize}>{educations.school}</Text>
                </View>
                <Text style={styles.date}>{start.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                })} - {end.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                })}</Text></View>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
            <Text style={styles.languageLevel}>{language.level}</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View style={styles.main}>
                    <View style={styles.sidebar}>
                        <View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Personal Details</Text>
                                <View style={styles.personal}>
                                    <Text style={styles.email}>{email}</Text>
                                    <Text style={styles.phone}>{phone}</Text>
                                    <Text style={styles.phone} >{country}, {town}</Text>
                                    <View style={styles.contactsRow}>
                                        {website && <View style={styles.social}><IconLink fill='#666666' /> <Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                                        {linkedin && <View style={styles.social}><IconLinkedin fill='#666666' /> <Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                                        {git && <View style={styles.social}><IconBxGitRepoForked fill='#666666' /> <Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                                    </View>
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Skills</Text>
                                <View style={styles.skills}>
                                    {formattedSkills}
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Languages</Text>
                                <View style={styles.col}>
                                    {formattedLanguages}
                                </View>
                            </View>
                            <View style={styles.sectionNoBorder}>
                                <Text style={styles.sectionTitle}>Education</Text>
                                <View style={styles.work}>{formattedEdu}</View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.topSection}>
                            <View style={styles.topSectionContent}>
                                <Text style={styles.name}>{firstName} {lastName}</Text>
                            </View>
                            {imageSrc && <Image style={styles.image} src={imageSrc as string} />}

                        </View>
                        <View style={styles.section}>
                            <Text>{introInformation}</Text>
                        </View>
                        <View style={styles.sectionNoBorder}>
                            <Text style={styles.sectionTitle}>Employment History</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Template002;
