import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { CiLinkedin } from "react-icons/ci";
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconGithub, IconLink, IconLinkedin, IconLinkedin2, IconLocationDot, IconMail, IconPhone } from '../icons';
import { FormatDate } from '../format-date';
import effect from './effect.png';

Font.register({ family: 'Kanit', src: '/fonts/Kanit/Kanit-Regular.ttf' });
Font.register({ family: 'RobotoMedium', src: '/fonts/Roboto/Roboto-Medium.ttf' });

const TITLE_BACKGROUND_COLOR = '#1A3636';
const color = TITLE_BACKGROUND_COLOR;
const colorSecondary = '#222222';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 9,
        gap: 4,
        color: '#444444',
        width: '100%'
    },
    section: {
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        paddingBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        width: '100%'
    },
    contactsSection: {
        paddingBottom: 15,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 8,
        flexWrap: 'wrap',
        width: '100%'
    },
    sectionTitle: {
        fontSize: 13,
        color: '#D6BD98',
        fontFamily: 'Kanit',
        marginBottom: 12,
        padding: 3,
        backgroundColor: TITLE_BACKGROUND_COLOR,
        textAlign: 'center'
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '62%',
        wordWrap: 'break-word'
    },
    sidebar: {
        width: '33%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    name: {
        fontSize: '26px',
        fontFamily: 'Helvetica-Bold',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        color: color,
    },
    top: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 11,
        width: '100%',
        paddingBottom: 10,
        paddingRight: 30,
    },
    email: {
        fontSize: 9,
        textAlign: 'left'
    },
    phone: {
        fontSize: 9,
        textAlign: 'left'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        width: '100%',
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
    },
    imageView: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        width: '80px',
        height: 'auto',
    },
    title: {
        color: colorSecondary,
        fontFamily: 'Helvetica-Bold',
        fontSize: 12
    },
    emphasize: {
        fontSize: 10,
        color: '#111111'
    },
    date: {
        color: '#666666',
        fontSize: 9
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
    },
    link: {
        fontSize: 9,
        textDecoration: 'none',
        color: '#222222',
    },
    contacts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 4,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    skill: {
        fontSize: 9,
        color: colorSecondary,
        padding: 2,
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
        // justifyContent: 'space-between',
    },
    language: {
        fontSize: 9,
        color: colorSecondary,
        padding: 2,
    },
    languageLevel: {
        fontSize: 9,
    },
    desired: {
        color: colorSecondary,
        fontSize: 13,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        fontFamily: 'Helvetica-Bold',
    },
    icon: {
        backgroundColor: TITLE_BACKGROUND_COLOR,
        borderRadius: '50%',
        padding: 3,
        marginRight: 2
    },
    effect: {
        position: 'absolute',
        width: '30%',
        height: 'auto',
        opacity: 0.2,
        top: -18,
        right: -110,
        transform: 'rotate(90deg)'
    },
    effectBottom: {
        position: 'absolute',
        width: '30%',
        height: 'auto',
        opacity: 0.2,
        top: 600,
        right: -80,

    },
});

const Template010 = ({ value }: { value: UserContextType, logo?: boolean }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        skills,
        languages,
        town,
        address,
        introInformation,
        certificates,
        works,
        educations,
        imageSrc,
        desiredPosition,
    } = value;
    const formattedWorks = works.map(work => {
        return <View style={styles.work}>
            <Text style={styles.title}>{work.jobTitle}</Text>
            <Text style={styles.emphasize}>{work.employer}</Text>
            <Text style={styles.date}>{FormatDate(work.start)} - {FormatDate(work.end)}</Text>
            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        return <View key={educations.id} style={styles.work}>
            <View style={styles.col}>
                <Text style={styles.title}>{educations.degree} in {educations.fieldOfStudy}</Text>
                <Text style={styles.emphasize}>{educations.school}</Text>
                <Text style={styles.date}>{FormatDate(educations.start)} - {FormatDate(educations.end)}</Text>
            </View>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
            <Text style={styles.languageLevel}>{language.level}</Text>
        </View>
    });
    const formattedCertificates = certificates.map(cert => {
        return <View key={cert.id} style={styles.work}>
            <View style={styles.col}>
                <Text style={styles.emphasize}>{cert.certificate}</Text>
                <Text>{cert.description}</Text>
            </View>
        </View>
    });
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View fixed>
                    <Image style={styles.effect} src={effect as string} />
                    <Image style={styles.effectBottom} src={effect as string} />
                </View>
                <View style={styles.topSection}>
                    {imageSrc && <View style={styles.imageView}><Image style={styles.image} src={imageSrc as string} /></View>}
                    <View style={styles.top}>
                        <Text style={styles.name}>{firstName} {lastName}</Text>
                        <Text style={styles.desired}>{desiredPosition}</Text>
                    </View>
                </View>

                <View style={styles.main}>
                    <View style={styles.sidebar}>
                        <View>
                            <View style={styles.contactsSection}>
                                {email && <View style={styles.social}><View style={styles.icon}><IconMail fill='#D6BD98' /></View><Text style={styles.link}>{email}</Text></View>}
                                {phone && <View style={styles.social}><View style={styles.icon}><IconPhone fill='#D6BD98' /></View><Text style={styles.link}>{phone}</Text></View>}
                                {website && <View style={styles.social}><View style={styles.icon}><IconLink fill='#D6BD98' /></View><Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                                {linkedin && <View style={styles.social}><View style={styles.icon}><IconLinkedin2 fill='#D6BD98' /></View><Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                                {git && <View style={styles.social}><View style={styles.icon}><IconGithub fill='#D6BD98' /></View><Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                                <View style={styles.social}><View style={styles.icon}><IconLocationDot fill='#D6BD98' /></View><Text style={styles.link} >{country}, {town}</Text></View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Skills</Text>
                                <View style={styles.skills}>
                                    {formattedSkills}
                                </View>
                            </View>
                            {languages.length && <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Languages</Text>
                                <View style={styles.skills}>
                                    {formattedLanguages}
                                </View>
                            </View>}
                            {certificates.length && <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Certificates</Text>
                                <View style={styles.skills}>
                                    {formattedCertificates}
                                </View>
                            </View>}
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Work Experience</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Education</Text>
                            <View style={styles.work}>{formattedEdu}</View>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    );
};

export default Template010;

