import React from "react";
import { useLocation, useNavigate } from "react-router";
import PAGES from "../pages";
import Button from "./button";

const NavButtons = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let index = 0;
    for (let i = 0; i < PAGES.length; i++) {
        if (location.pathname === PAGES[i].route) {
            index = i;
            break;
        }
    }

    const onNext = () => {
        if(index < PAGES.length - 1) {
            navigate(PAGES[index + 1].route);
        }
    }
    const onPrev = () => {
        if(index > 0) {
            navigate(PAGES[index - 1].route);
        }
    }
    return <div className="w-full max-w-7xl flex flex-row items-center gap-10 pt-2 mt-8 border-t border-slate-300 pb-11">
        {index > 0 ? <Button onClick={onPrev} variant="secondary" className="grow mb-8">Prev</Button> : <div className="grow"></div>}
        {index < PAGES.length - 1 ? <Button className="grow mb-8" onClick={onNext}>Next</Button> : <div className="grow"></div>}
    </div>
}
export default NavButtons;