import React from "react";
import { useTranslation } from "react-i18next";
import { Template } from "../templates/templates";
import { useUser } from "../user-context";
import Button from "./button";
import Dialog from "./dialog";
import PDFCVGenerator from "./pdf-cv-generator";

interface ChoseTemplateDialogProps {
    open: boolean;
    setOpen: (v: boolean) => void;
    template: Template | undefined;
    onSelect: () => void;
}
const ChoseTemplateDialog = ({ open, setOpen, template, onSelect }: ChoseTemplateDialogProps) => {
    const { t } = useTranslation();
    const { downloadCV } = useUser();

    return <Dialog open={open} setOpen={setOpen} title={"Use This Template"} className={"w-full max-w-[1000px]"} closeIcon={true}>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8 py-3">
            <div className="w-full h-full flex items-center justify-center"><img className="w-48 md:w-full h-auto border hover:border-4 border-slate-300" src={template?.image} /></div>
            <div className="flex flex-col items-stretch h-full gap-8">
                <div className="lg:pl-2 text-2xl font-sans text-slate-800">{template?.name && t(template.name)}</div>
                <div className="grow text-slate-600 lg:pl-2 lg:pr-8 whitespace-pre-line">{template?.description && t(template.description)}</div>
                <div className="flex items-center w-full justify-end gap-8">
                    <Button onClick={() => setOpen(false)} variant="secondary">{t("cancel")}</Button>
                    <Button onClick={onSelect}>{t("select&preview")}</Button>
                    {/* <Button className="visible md:hidden" onClick={downloadCV}>Download as PDF</Button> */}
                </div>
            </div>
        </div>
    </Dialog>
}
export default ChoseTemplateDialog;