export function FormatDate(date: Date | string | null) {
    if (date) {
        if (date == "Now")
            return date;
        const toDate = new Date(date as any);
        return date.toLocaleString("default", {
            month: "short",
            year: "numeric",
        });
    }
    return "";
}