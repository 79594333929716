import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import Input from "../components/input";
import NavButtons from "../components/nav-buttons";
import Select from "../components/select";
import { LanguageUnit, useUser } from "../user-context";
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as Edit } from '../assets/svg/edit_square.svg';
import { ReactComponent as Down } from '../assets/svg/keyboard_arrow_down.svg';
import { ReactComponent as Up } from '../assets/svg/keyboard_arrow_up.svg';

const Languages = () => {
    const { t } = useTranslation();
    const { languages, setLanguages } = useUser();
    const moveUp = (id: string) => {
        const language = languages.filter(language => language.id === id)[0];
        const index = languages.indexOf(language);
        if (index > 0) {
            const newLanguages = languages.filter(language => language.id !== id);
            newLanguages.splice(index - 1, 0, language);
            setLanguages(newLanguages);
        }
    }

    const moveDown = (id: string) => {
        const language = languages.filter(language => language.id === id)[0];
        const index = languages.indexOf(language);
        if (index < languages.length - 1) {
            const newLanguages = languages.filter(language => language.id !== id);
            newLanguages.splice(index + 1, 0, language);
            setLanguages(newLanguages);
        }
    }

    const deleteElement = (id: string) => {
        setLanguages(languages.filter(language => language.id !== id));
    }

    const addLanguage = (id: string | null,
        language: string,
        level: string) => {
        if (!id) {
            id = language + "_" + Math.random();
            const newLanguage = { id, language, level };
            setLanguages([...languages, newLanguage]);
        } else {
            const newLanguage = { id, language, level };
            const newLanguages = [];
            for (let language of languages) {
                if (language.id != id) {
                    newLanguages.push(language);
                } else {
                    newLanguages.push(newLanguage);
                }
            }
            setLanguages(newLanguages);
        }
    }

    const mapped = languages.map(language => <LanguageGroup key={language.id} language={language} deleteElement={deleteElement} moveDown={moveDown} moveUp={moveUp} addLanguage={addLanguage} />);

    return <div className='w-full max-w-7xl h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10 items-stretch'>
        <div><h1 className='text-xl font-bold'>{t('languages')}</h1></div>
        <div className="w-full py-4 md:py-8 flex flex-col items-stretch gap-2 md:gap-8">
            {mapped}
        </div>
        <div className="text-lg font-bold">Add New Language</div>
        <LanguageForm addLanguage={addLanguage} />
        <NavButtons />
    </div>;
}
export default Languages;


const LanguageGroup = ({ language, moveDown, moveUp, deleteElement, addLanguage }: {
    language: LanguageUnit, moveDown: (id: string) => void;
    moveUp: (id: string) => void;
    deleteElement: (id: string) => void;
    addLanguage: (id: string | null,
        language: string,
        level: string) => void;
}) => {
    const [show, setShow] = React.useState(false);

    return <div className="border-b border-slate-300 py-2">
        {!show && <div key={language.id} className="flex flex-row items-start justify-between">
            <div className="flex flex-col items-start">
                <div className="text-lg flex items-center font-bold">{language.language}</div>
                <div className="text-sm font-normal">{language.level}</div>
            </div>
            <div className="flex flex-row items-center gap-3">
                <button onClick={() => setShow(true)}><Edit className='w-6 h-6 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveDown(language.id)}><Down className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveUp(language.id)}><Up className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => deleteElement(language.id)}><Close className='w-8 h-8 fill-slate-400 hover:fill-slate-600' /></button>
            </div>
        </div>}
        {show && <LanguageForm language={language} addLanguage={addLanguage} cancel={() => setShow(false)} />}
    </div>
}


const LanguageForm = ({ addLanguage, language, cancel }: {
    language?: LanguageUnit;
    cancel?: () => void;
    addLanguage: (id: string | null,
        language: string,
        level: string) => void
}) => {
    const [languageName, setLanguageName] = useState<string>(language?.language || "");
    const [level, setLevel] = useState<string>(language?.level || "");

    const levelOptions = LANGUAGE_LEVELS.map(el => ({
        value: el,
        label: el // translation
    }));

    const onDelete = () => {
        setLanguageName(language?.language || "");
        setLevel(language?.level || "");
    }

    return <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-w-7xl border-tsDarkBlue">
        <Input className="grow" label="Language" value={languageName} onChange={(e) => setLanguageName(e.target.value)} />
        <Select label={"Level"} options={levelOptions} onChange={(v: any) => setLevel(v.value)} defaultValue={language?.level ? levelOptions.filter(o => o.value == language?.level)[0] : ""} />
        <div></div>
        <div className="flex flex-row items-center gap-10">
            {language ? <Button onClick={cancel} className="grow" variant="secondary">Cancel</Button> :
                <Button onClick={onDelete} className="grow" variant="secondary">Delete</Button>}
            <Button className="grow" onClick={() => {
                addLanguage(language?.id || null, languageName, level);
                onDelete();
                cancel && cancel();
            }}>Save</Button>
        </div>
    </div>
}

const LANGUAGE_LEVELS = [
    "Basic",
    "Conversational",
    "Proficient",
    "Fluent",
    "Native"
]