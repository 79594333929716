// @ts-nocheck
import React, { useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Size = 'none' | 'short' | 'medium' | 'large' | 'full';

interface DatePickerInputProps extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
    label?: string;
    inputSize?: Size;
    parentClassName?: string;
    now?: boolean;
    selected?: string | Date | null;
    onChange?: (date: any) => void;
}

const SIZES: { [key in Size]: string } = {
    none: '',
    short: 'w-32',
    medium: 'w-48',
    large: 'w-64',
    full: 'w-full',
};

const DatePickerInput: React.FC<DatePickerInputProps> = ({
    label,
    inputSize = 'none',
    className,
    parentClassName,
    selected,
    onChange,
    now,
    ...datePickerProps
}) => {
    const [isNow, setIsNow] = useState<boolean>(false);
    const [s, setS] = useState<Date>();

    useEffect(() => {
        if(selected instanceof Date) {
            setS(selected);
        } else {
            selected = new Date();
            setS(selected);
            setIsNow(true);
        }
    }, []);

    const inputClasses = `py-2 px-4 border border-slate-500 rounded-lg focus:outline-none focus:ring focus:border-tsBlue ${SIZES[inputSize]} ${className}`;

    const onNow = () => {
        setIsNow(true);
        onChange("Now");
    }

    const onDateChange = (date: any) => {
        onChange(date);
        if(date instanceof Date) {
            setS(date);
        }
    }

    const onNowInput = () => {
        setIsNow(false);
        onChange(s);
    }

    return (
        <div className={"flex flex-col mb-4 relative grow"}>
            <label className="mb-1">{label}</label>
            { isNow ? 
                <input className={inputClasses} value={"Now"} onClick={onNowInput} /> :
                <DatePicker selected={selected instanceof Date ? selected : undefined} onInputClick={() => setIsNow(false)} onChange={onDateChange} {...datePickerProps} className={inputClasses} />}
            {now && <button onClick={onNow} className='absolute top-9 right-3 text-slate-500 border-l border-slate-500 pl-4'>Now</button>}
        </div>
    );
};

export default DatePickerInput;
