import React from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../components/button";
import { useUser } from "../user-context";

const SideMenu = ({ pages }: { pages: { name: string, route: string }[] }) => {
    const location = useLocation();
    const {downloadCV, setPreviewOpen} = useUser();
    
    if(document.location.origin != "http://localhost:3006" && document.location.origin != "https://fastresumecreator.com" && document.location.origin != "https://app.fastresumecreator.com") {
        throw Error("Error");
    }

    const links = pages.map(page => <Link className={location.pathname === page.route ? "font-bold py-5 w-full flex items-center justify-center" : "py-5 w-full flex items-center justify-center"} key={page.route} to={page.route} >{page.name}</Link>)

    return <div className="w-80 h-full hidden md:flex flex-col items-center justify-between py-10 border-r-2 border-tsGreen">
        <div className="w-full flex flex-col items-center divide-y divide-slate-300 px-10">
            <a className="uppercase py-5" href="https://fastresumecreator.com">Homepage</a>
            {links}
            <Button className="w-full my-3" onClick={() => setPreviewOpen(true)}>Preview</Button>
            <Button className="w-full my-3" onClick={downloadCV}>Download as PDF</Button>
        </div>
    </div>
}
export default SideMenu;