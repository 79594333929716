import React, { useState } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';

type Size = "none" | "short" | "medium" | "large" | "full";

interface SelectWrapperProps extends Omit<SelectProps, 'size'> {
    label?: string;
    options?: { value: string; label: string }[];
    selectSize?: Size;
}

const SIZES: { [key in Size]: string } = {
    "none": "",
    "short": "h-8",
    "medium": "h-10",
    "large": "h-12",
    "full": "h-full",
};

const WRAPPER_SIZES: { [key in Size]: string } = {
    "none": "",
    "short": "",
    "medium": "",
    "large": "",
    "full": "h-full",
};

const Select: React.FC<SelectWrapperProps> = ({ label, options, selectSize, className, ...selectProps }) => {
    selectSize = selectSize ? selectSize : "none";
    className = className || "";

    const wrapperClasses = WRAPPER_SIZES[selectSize];
    const selectClasses = SIZES[selectSize];

    return (
        <div className={`flex flex-col mb-4 ${wrapperClasses} ${className}`}>
            <label className="mb-1">{label}</label>
            <ReactSelect
                {...selectProps}
                options={options}
                // value={selectedOption}
                // onChange={(selectedOption) => setSelectedOption(selectedOption)}
                className={`w-full border border-slate-500 rounded-lg focus:outline-none focus:ring focus:border-tsBlue ${selectClasses} ${className}`}
            />
        </div>
    );
};

export default Select;