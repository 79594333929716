import React, { useEffect, useState } from 'react';
import Template000 from '../templates/template000/template000';
import { useUser } from '../user-context';

// Create PDF component
const PDFBlobPreview = () => {
    const [src, setSrc] = useState<string>("");
    const context = useUser();

    useEffect(() => {
        const load = async () => {
            const res = await context.getPDFBlob();
            setSrc(res);
        }
        load();
    }, []);
    
    return (
        <iframe src={src} className="w-full h-full">
        {/* <Template000 value={context} /> */}
        </iframe>
    );
};

export default PDFBlobPreview;
