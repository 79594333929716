import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';
import { UserContextType } from '../../user-context';

Font.register({ family: 'Roboto', src: '/fonts/Roboto/Roboto-Regular.ttf' });
Font.register({ family: 'RobotoBold', src: '/fonts/Roboto/Roboto-Bold.ttf' });
Font.register({ family: 'RobotoMedium', src: '/fonts/Roboto/Roboto-Medium.ttf' });

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 12,
    },
    section: {
        flexGrow: 1,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    sectionTitle: {
        fontSize: '15px',
        fontFamily: 'RobotoMedium',
        textAlign: 'center',
        paddingBottom: 10
    },
    name: {
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: 'RobotoMedium',
        textAlign: 'center'
    },
    email: {
        fontSize: '13px',
        textAlign: 'center'
    },
    phone: {
        fontSize: '13px',
        textAlign: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    emphasize: {
        display: 'flex',
        flexDirection: 'row',
        
        width: '100%'
    },
    work: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%'
    },
    image: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '20px',
        width: '100px',
        height: 'auto'
    }
});

const Template000 = ({ value }: { value: UserContextType }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        skills,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
    } = value;
    const formattedWorks = works.map(work => {
        const start = new Date(work.start as any);
        const end = (work.end === "Now") ? work.end : new Date(work.end as any);
        return <View style={styles.work}>
            <Text style={styles.row}>{start.toLocaleString("default", {
                month: "long",
                year: "numeric",
            })} - {end === "Now" ? end : end.toLocaleString("default", {
                month: "long",
                year: "numeric",
            })}: {work.jobTitle} at {work.employer}</Text>
            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(education => {
        const start = new Date(education.start as any);
        const end = (education.end === "Now") ? education.end : new Date(education.end as any);
        return <View key={education.id} style={styles.work}>
            <Text style={styles.row}>{start.toLocaleString("default", {
                month: "long",
                year: "numeric",
            })} -  {end === "Now" ? end : end.toLocaleString("default", {
                month: "long",
                year: "numeric",
            })} in {education.fieldOfStudy}, {education.school}</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View>
                    <View style={styles.section}>
                        {imageSrc && <Image style={styles.image} src={imageSrc as string} />}
                        <Text style={styles.name}>{firstName} {lastName}</Text>
                        <Text style={styles.email}>{email}</Text>
                        <Text style={styles.phone}>{phone}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>About me</Text>
                        <Text>{introInformation}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Skills</Text>
                        <Text>{skills.map(skill => <Text>{skill.skill}, </Text>)}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Employment History</Text>
                        <View style={styles.work}>{formattedWorks}</View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Education</Text>
                        <View style={styles.work}>{formattedEdu}</View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Template000;
