import { Dialog as DialogBase, Transition } from '@headlessui/react'
import React, { Fragment, PropsWithChildren, ReactNode } from 'react'
import { ReactComponent as Close } from '../assets/svg/close.svg';

interface DialogBaseProps extends PropsWithChildren {
    open: boolean;
    setOpen: (v: boolean) => void;
    content?: ReactNode;
    title?: ReactNode;
    closeIcon?: boolean;
    trigger?: JSX.Element;
    className?: string;
}
export default function Dialog({
    open,
    setOpen,
    content,
    title,
    closeIcon,
    trigger,
    children,
    className
}: DialogBaseProps) {
    return (
        <>
            {trigger}

            <Transition appear show={open} as={Fragment}>
                <DialogBase as="div" className="relative z-10" onClose={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogBase.Panel className={"transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all flex flex-col items-stretch " + className}>
                                    <DialogBase.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between w-full"
                                    >
                                        {title} {closeIcon && <button onClick={() => setOpen(false)}><Close className='w-8 h-8 fill-slate-400 hover:fill-slate-600' /></button>}
                                    </DialogBase.Title>

                                    {content}
                                    {children}

                                </DialogBase.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </DialogBase>
            </Transition>
        </>
    )
}
