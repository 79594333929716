import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import Input from "../components/input";
import NavButtons from "../components/nav-buttons";
import { EducationUnit, useUser } from "../user-context";
import DatePickerInput from "../components/datepicker";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { ReactComponent as Edit } from "../assets/svg/edit_square.svg";
import { ReactComponent as Down } from "../assets/svg/keyboard_arrow_down.svg";
import { ReactComponent as Up } from "../assets/svg/keyboard_arrow_up.svg";
import Certificates from "./certificates";

const Education = () => {
    const { educations, setEducations } = useUser();
    const { t } = useTranslation();

    const addEducation = (
        id: string | null,
        degree: string,
        school: string,
        fieldOfStudy: string,
        start: Date | null,
        end: Date | string | null
    ) => {
        if (!id) {
            id = degree + "_" + school + "_" + Math.random();
            const newEducation = { id, degree, school, fieldOfStudy, start, end };
            setEducations([...educations, newEducation]);
        } else {
            const newEducation = { id, degree, school, fieldOfStudy, start, end };
            const nEducations = [];
            for (let education of educations) {
                if (education.id != id) {
                    nEducations.push(education);
                } else {
                    nEducations.push(newEducation);
                }
            }
            setEducations(nEducations);
        }
    };

    const moveUp = (id: string) => {
        const education = educations.filter((ed) => ed.id === id)[0];
        const index = educations.indexOf(education);
        if (index > 0) {
            const nEducations = educations.filter((ed) => ed.id !== id);
            nEducations.splice(index - 1, 0, education);
            setEducations(nEducations);
        }
    };

    const moveDown = (id: string) => {
        const education = educations.filter((ed) => ed.id === id)[0];
        const index = educations.indexOf(education);
        if (index < educations.length - 1) {
            const nEducations = educations.filter((ed) => ed.id !== id);
            nEducations.splice(index + 1, 0, education);
            setEducations(nEducations);
        }
    };

    const deleteElement = (id: string) => {
        setEducations(educations.filter((ed) => ed.id !== id));
    };

    const mapped = educations.map((ed) => (
        <EducationGroup
            ed={ed}
            key={ed.id}
            moveDown={moveDown}
            moveUp={moveUp}
            addEducation={addEducation}
            deleteElement={deleteElement}
        />
    ));

    return (
        <div className="w-full max-w-7xl h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10">
            <div>
                <h1 className="text-xl font-bold">{t("education")}</h1>
            </div>
            <div className="w-full py-4 md:py-8 flex flex-col items-stretch gap-2 md:gap-8">
                {mapped}
            </div>
            <div className="flex flex-col items-stretch gap-3">
                <div className="text-xl">Add Education</div>
                <EducationForm addEducation={addEducation} />
            </div>
            <NavButtons />
        </div>
    );
};
export default Education;

const EducationGroup = ({
    ed,
    moveDown,
    moveUp,
    deleteElement,
    addEducation,
}: {
    ed: EducationUnit;
    moveDown: (id: string) => void;
    moveUp: (id: string) => void;
    deleteElement: (id: string) => void;
    addEducation: (
        id: string | null,
        degree: string,
        school: string,
        fieldOfStudy: string,
        start: Date | null,
        end: Date | string | null
    ) => void;
}) => {
    const [show, setShow] = React.useState(false);
    return (
        <div className="flex w-full flex-col-reverse md:flex-row items-start justify-between border-b border-slate-300 py-2">
            {!show && <>
                <div className="flex flex-col items-start grow">
                    <div className="text-xs text-slate-600">
                        {ed.start?.toLocaleString("default", {
                            month: "long",
                            year: "numeric",
                        })}{" "}
                        -{" "}
                        {ed.end?.toLocaleString("default", {
                            month: "long",
                            year: "numeric",
                        })}
                    </div>
                    <div className="font-bold text-base">
                        {ed.degree} of {ed.fieldOfStudy}
                    </div>
                    <div className="text-lg">{ed.school}</div>
                </div>
                <div className="flex flex-row items-center justify-center md:justify-end gap-1 md:gap-3">
                    <button onClick={() => setShow(true)}>
                        <Edit className="w-6 h-6 fill-slate-400 hover:fill-slate-600" />
                    </button>
                    <button onClick={() => moveDown(ed.id)}>
                        <Down className="w-11 h-11 fill-slate-400 hover:fill-slate-600" />
                    </button>
                    <button onClick={() => moveUp(ed.id)}>
                        <Up className="w-11 h-11 fill-slate-400 hover:fill-slate-600" />
                    </button>
                    <button onClick={() => deleteElement(ed.id)}>
                        <Close className="w-8 h-8 fill-slate-400 hover:fill-slate-600" />
                    </button>
                </div>
            </>}
            {show && <EducationForm addEducation={addEducation} education={ed} cancel={() => setShow(false)} />}
        </div>
    );
};

const EducationForm = ({
    addEducation,
    education,
    cancel
}: {
    addEducation: (
        id: string | null,
        degree: string,
        school: string,
        fieldOfStudy: string,
        start: Date | null,
        end: Date | string | null
    ) => void;
    education?: EducationUnit;
    cancel?: () => void;
}) => {
    const [degree, setDegree] = useState(education?.degree || "");
    const [school, setSchool] = useState(education?.school || "");
    const [fieldOfStudy, setFieldOfStudy] = useState(education?.fieldOfStudy || "");
    const [startDate, setStartDate] = useState<Date | null>(education?.start || new Date());
    const [endDate, setEndDate] = useState<Date | string | null>(education?.end || new Date());

    const reset = () => {
        setDegree(education?.degree || "");
        setSchool(education?.school || "");
        setFieldOfStudy(education?.fieldOfStudy || "");
        setStartDate(education?.start || new Date());
        setEndDate(education?.end || new Date());
    }

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-w-7xl border-tsDarkBlue">
            <Input
                label="Degree"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
            />
            <Input
                label="Field Of Study"
                value={fieldOfStudy}
                onChange={(e) => setFieldOfStudy(e.target.value)}
            />
            <Input
                label="School"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
            />
            <div className="flex flex-row items-center justify-between gap-10">
                <DatePickerInput
                    label={"Start"}
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    inputSize="full"
                    showMonthYearPicker
                />
                <DatePickerInput
                    label={"End"}
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    dateFormat="MM/yyyy"
                    inputSize="full"
                    showMonthYearPicker
                    now={true}
                />
            </div>
            <div></div>
            <div className="flex flex-row items-center gap-10">
                {education ? <Button onClick={cancel} className="grow" variant="secondary">
                        Cancel
                    </Button> :
                    <Button onClick={reset} className="grow" variant="secondary">
                        Delete
                    </Button>}
                <Button
                    className="grow"
                    onClick={() => {
                        addEducation(
                            education?.id || null,
                            degree,
                            school,
                            fieldOfStudy,
                            startDate,
                            endDate
                        );
                        cancel && cancel();
                        reset()
                    }}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};
