import React, { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'secondary' | 'danger';
}

const Button: React.FC<ButtonProps> = ({ variant = 'primary',  className, children, ...buttonProps }) => {
    let variantClasses = 'bg-tsDarkBlue hover:bg-tsBlue text-white';
    if (variant === 'secondary') {
        variantClasses = 'bg-gray-500 hover:bg-gray-600 text-white';
    } else if (variant === 'danger') {
        variantClasses = 'bg-tsRed hover:bg-tsRed text-white'
    }

    return (
        <button
            {...buttonProps}
            className={`py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-transparent ${variantClasses} ${className}`}
        >
            {children}
        </button>
    );
};

export default Button;
