import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import DatePickerInput from "../components/datepicker";
import Input from "../components/input";
import NavButtons from "../components/nav-buttons";
import { useUser, WorkUnit } from "../user-context";
import { EditorState } from "draft-js";
import { ReactComponent as Close } from "../assets/svg/close.svg";
import { ReactComponent as Edit } from "../assets/svg/edit_square.svg";
import { ReactComponent as Down } from "../assets/svg/keyboard_arrow_down.svg";
import { ReactComponent as Up } from "../assets/svg/keyboard_arrow_up.svg";
import EditorArea from "../components/editor";
import TextArea from "../components/textarea";

const Experience = () => {
    const { works, setWorks } = useUser();
    const { t } = useTranslation();

    const addWork = (
        id: string | null,
        jobTitle: string,
        employer: string,
        location: string,
        start: Date | null,
        end: Date | string | null,
        details: string
    ) => {
        if (!id) {
            id = jobTitle + "_" + employer + "_" + Math.random();
            const newWork = { id, jobTitle, employer, start, end, details, location };
            setWorks([...works, newWork]);
        } else {
            const newWork = { id, jobTitle, employer, start, end, details, location };
            const newWorks = [];
            for (let work of works) {
                if (work.id != id) {
                    newWorks.push(work);
                } else {
                    newWorks.push(newWork);
                }
            }
            setWorks(newWorks);
        }
    };

    const moveUp = (id: string) => {
        const work = works.filter((w) => w.id === id)[0];
        const index = works.indexOf(work);
        if (index > 0) {
            const nWorks = works.filter((w) => w.id !== id);
            nWorks.splice(index - 1, 0, work);
            setWorks(nWorks);
        }
    };

    const moveDown = (id: string) => {
        const work = works.filter((w) => w.id === id)[0];
        const index = works.indexOf(work);
        if (index < works.length - 1) {
            const nWorks = works.filter((w) => w.id !== id);
            nWorks.splice(index + 1, 0, work);
            setWorks(nWorks);
        }
    };

    const deleteElement = (id: string) => {
        setWorks(works.filter((w) => w.id !== id));
    };

    const mapped = works.map((ed) => (
        <ExperienceGroup
            key={ed.id}
            work={ed}
            updateWork={addWork}
            deleteElement={deleteElement}
            moveDown={moveDown}
            moveUp={moveUp}
        />
    ));

    return (
        <div className="w-full max-w-7xl h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10">
            <div>
                <h1 className="text-xl font-bold">{t("experiences")}</h1>
            </div>
            <div className="w-full py-4 md:py-8 flex flex-col items-stretch gap-2 md:gap-8">
                {mapped}
            </div>
            <div className="flex flex-col items-stretch gap-3">
                <div className="text-xl">Add Work Experience</div>
                <ExperienceForm addWork={addWork} />
            </div>
            <NavButtons />
        </div>
    );
};
export default Experience;

const ExperienceGroup = ({
    work,
    updateWork,
    moveDown,
    moveUp,
    deleteElement,
}: {
    moveDown: (id: string) => void;
    moveUp: (id: string) => void;
    deleteElement: (id: string) => void;
    work: WorkUnit;
    updateWork: (
        id: string | null,
        jobTitle: string,
        employer: string,
        location: string,
        start: Date | null,
        end: Date | string | null,
        details: string
    ) => void;
}) => {
    const [show, setShow] = React.useState(false);

    return (
        <div className="border-b border-slate-300 py-2 w-full">
            {!show && (
                <div className="flex flex-col-reverse md:flex-row items-start justify-between w-full">
                    <div className="flex flex-col items-start grow">
                        <div className="text-xs text-slate-600">
                            {work.start?.toLocaleString("default", {
                                month: "long",  
                                year: "numeric",
                            })}{" "}
                            -{" "}
                            {work.end?.toLocaleString("default", {
                                month: "long",
                                year: "numeric",
                            })}
                        </div>
                        <div className="font-bold text-base">{work.jobTitle}</div>
                        <div className="text-lg">{work.employer}</div>
                        <div className="w-full text-sm">{work.details}</div>
                    </div>
                    <div className="flex flex-row items-center justify-center md:justify-start gap-1 md:gap-3">
                        <button onClick={() => setShow(true)}>
                            <Edit className="w-6 h-6 fill-slate-400 hover:fill-slate-600" />
                        </button>
                        <button onClick={() => moveDown(work.id)}>
                            <Down className="w-11 h-11 fill-slate-400 hover:fill-slate-600" />
                        </button>
                        <button onClick={() => moveUp(work.id)}>
                            <Up className="w-11 h-11 fill-slate-400 hover:fill-slate-600" />
                        </button>
                        <button onClick={() => deleteElement(work.id)}>
                            <Close className="w-8 h-8 fill-slate-400 hover:fill-slate-600" />
                        </button>
                    </div>
                </div>
            )}
            {show && <ExperienceForm addWork={updateWork} work={work} cancel={() => setShow(false)} />}
        </div>
    );
};

const ExperienceForm = ({
    work,
    addWork,
    cancel,
}: {
    work?: WorkUnit,
    cancel?: () => void,
    addWork: (
        id: string | null,
        jobTitle: string,
        employer: string,
        location: string,
        start: Date | null,
        end: Date | string | null,
        details: string
    ) => void,
}) => {
    const [jobTitle, setJobTitle] = useState(work?.jobTitle || "");
    const [employer, setEmployer] = useState(work?.employer || "");
    const [location, setLocation] = useState(work?.location || "");
    const [startDate, setStartDate] = useState<Date | null>(work?.start || new Date());
    const [endDate, setEndDate] = useState<Date | string | null>(work?.end || new Date());
    const [details, setDetails] = useState(work?.details || "");

    const onDelete = () => {
        setJobTitle(work?.jobTitle || "");
        setEmployer(work?.employer || "");
        setLocation(work?.location || "");
        setStartDate(work?.start || new Date());
        setEndDate(work?.end || new Date());
        setDetails(work?.details || "");
    }

    useEffect(() => {
        onDelete();
    }, [])

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-w-7xl border-tsDarkBlue">
            <Input
                label="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
            />
            <Input
                label="Employer"
                value={employer}
                onChange={(e) => setEmployer(e.target.value)}
            />
            <Input
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
            />
            <div className="flex flex-row items-center justify-between gap-10">
                <DatePickerInput
                    label={"Start"}
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    inputSize="full"
                    showMonthYearPicker
                />
                <DatePickerInput
                    label={"End"}
                    selected={endDate}
                    onChange={(date: Date | string) => setEndDate(date)}
                    dateFormat="MM/yyyy"
                    inputSize="full"
                    showMonthYearPicker
                    now={true}
                />
            </div>
            <div className="col-span-full">
                <TextArea label={""} value={details} onChange={(e) => setDetails(e.target.value)} />
            </div>
            <div></div>
            <div className="flex flex-row items-center gap-10">
                {work ? <Button onClick={cancel} className="grow" variant="secondary">
                    Cancel
                </Button> :
                    <Button onClick={onDelete} className="grow" variant="secondary">
                        Delete
                    </Button>}
                <Button
                    className="grow"
                    onClick={() => {
                        addWork(
                            work?.id || null,
                            jobTitle,
                            employer,
                            location,
                            startDate,
                            endDate,
                            details
                        );
                        onDelete();
                        cancel && cancel();
                    }}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};
