import Template000 from "./template000/template000";
import Template000Image from "./template000/template.jpg";
import Template001Image from "./template001/template1.jpg";
import Template002Image from "./template002/template2.jpg";
import Template003Image from "./template003/template3.jpg";
import Template004Image from "./template004/template4.jpg";
import Template005Image from "./template005/template5.jpg";
import Template006Image from "./template006/template6.jpg";
import Template007Image from "./template007/template7.jpg";
import Template008Image from "./template008/template8.jpg";
import Template009Image from "./template009/template9.jpg";
import Template010Image from "./template010/template10.jpg";
import { UserContextType } from "../user-context";
import Template001 from "./template001/template001";
import Template002 from "./template002/template002";
import Template003 from "./template003/template003";
import Template004 from "./template004/template004";
import Template005 from "./template005/template005";
import Template006 from "./template006/template006";
import Template007 from "./template007/template007";
import Template008 from "./template008/template008";
import Template009 from "./template009/template009";
import Template010 from "./template010/template010";

export interface Template {
    id: number,
    type: ({ value }: { value: UserContextType; }) => JSX.Element,
    name: string,
    description: string,
    image: string
}

const TEMPLATES: Template[] = [
    {
        id: 10,
        type: Template010,
        name: "Ambition",
        description: "ambition_template_description_template_10",
        image: Template010Image
    },
    {
        id: 9,
        type: Template009,
        name: "Dynamic",
        description: "dynamic_template_description_template_9",
        image: Template009Image
    },
    {
        id: 8,
        type: Template008,
        name: "Warm",
        description: "warm_template_description_template_8",
        image: Template008Image
    },
    {
        id: 7,
        type: Template007,
        name: "Air",
        description: "air_template_description_template_7",
        image: Template007Image
    },
    {
        id: 6,
        type: Template006,
        name: "Creative",
        description: "creatrive_template_description_template_6",
        image: Template006Image
    },
    {
        id: 5,
        type: Template005,
        name: "Advanced",
        description: "advanced_template_description_template_5",
        image: Template005Image
    },
    {
        id: 4,
        type: Template004,
        name: "Manager",
        description: "manager_template_description_template_4",
        image: Template004Image
    },
    {
        id: 3,
        type: Template003,
        name: "Business",
        description: "business_template_description_template_3",
        image: Template003Image
    },
    {
        id: 2,
        type: Template002,
        name: "Simple",
        description: "simple_template_description_template_2",
        image: Template002Image
    },
    {
        id: 1,
        type: Template001,
        name: "Pro",
        description: "pro_template_description_template_1",
        image: Template001Image
    },
    {
        id: 0,
        type: Template000,
        name: "Default",
        description: "default_template_description",
        image: Template000Image
    },
];

export default TEMPLATES;