import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Button from "../components/button";
import ChoseTemplateDialog from "../components/chose-template-dialog";
import NavButtons from "../components/nav-buttons";
import TEMPLATES, { Template } from "../templates/templates";
import { useUser } from "../user-context";

const Templates = () => {
    const [selected, setSelected] = useState<Template>();
    const [picked, setPicked] = useState<Template>();
    const [choseDialogOpen, setChoseDialogOpen] = useState<boolean>(false);
    const {templateId, setTemplateId, setPreviewOpen, downloadCV} = useUser();
    
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSelect = () => {
        if(picked) {
            setTemplateId(picked.id); 
            setPreviewOpen(true);    
        }
        setChoseDialogOpen(false);
    }

    const templates = TEMPLATES.map(template => <div key={template.id} onClick={() => { setPicked(template); setChoseDialogOpen(true)}} className="relative flex flex-col w-32 md:w-52">
        <img className={templateId === template.id ? "border hover:border-4 border-slate-300" : "border hover:border-4 border-green-300"} src={template.image} />
        <div className="absolute left-1 bottom-2 w-[96%] py-1 text-center text-sm font-bold bg-white bg-opacity-80">{template.name}</div>
    </div>);

    return <div className='w-full h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10'>
        <h1 className='text-xl font-bold'>{t('templates')}</h1>
        <div>Pick your template</div>

        {/* <div className="w-full mt-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10 max-w-7xl border-tsDarkBlue"> */}
        <div className="w-full mt-10 flex flex-row flex-wrap items-center justify-center gap-10 border-tsDarkBlue">
            {templates}
        </div>
        <div className="visible md:hidden py-6">
            {/* <Button className="w-full my-3" onClick={() => setPreviewOpen(true)}>Preview</Button> */}
            <Button className="w-full my-3" onClick={downloadCV}>Download as PDF</Button>
            <NavButtons />
        </div>
        <ChoseTemplateDialog open={choseDialogOpen} setOpen={setChoseDialogOpen} template={picked} onSelect={onSelect} />
    </div>;
}
export default Templates;
