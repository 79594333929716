import BasicInfo from "./layout/basic-info";
import Certificates from "./layout/certificates";
import Education from "./layout/education";
import Experience from "./layout/experience";
import Languages from "./layout/languages";
import Skills from "./layout/skills";
import Templates from "./layout/templates";

const PAGES = [
    {
        route: "/basic",
        component: <BasicInfo />,
        name: "Basic Information"
    },
    {
        route: "/skills",
        component: <Skills />,
        name: "Skills"
    },
    {
        route: "/languages",
        component: <Languages />,
        name: "Languages"
    },
    {
        route: "/education",
        component: <Education />,
        name: "Education"
    },
    {
        route: "/experience",
        component: <Experience />,
        name: "Work Experience"
    },
    {
        route: "/certificates",
        component: <Certificates />,
        name: "Certificates"
    },
    {
        route: "/templates",
        component: <Templates />,
        name: "Templates"
    }
];
export default PAGES;