import React, { InputHTMLAttributes } from 'react';

type Size = "none" | "short" | "medium" | "large" | "full";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    options?: string[];
    inputSize?: Size;
}

const SIZES: { [key: string]: string } = {
    "none": "",
    "short": "",
    "medium": "",
    "large": "",
    "full": "",
}
const WRAPPER_SIZES: { [key: string]: string } = {
    "none": "",
    "short": "",
    "medium": "",
    "large": "",
    "full": "",
}

const Input: React.FC<InputProps> = ({ label, options, inputSize, className, ...inputProps }) => {
    inputSize = inputSize ? inputSize : "none";
    className = className || "";

    const wrapperClasses = WRAPPER_SIZES[inputSize];
    const inputClasses = SIZES[inputSize];

    return (
        <div className={`flex flex-col mb-4 ${wrapperClasses} ${className}`}>
            <label className="mb-1">{label}</label>
            <input
                {...inputProps}
                className={`py-2 px-4 w-full border border-slate-500 rounded-lg focus:outline-none focus:ring focus:border-tsBlue ${inputClasses} ${className}`}
            />
        </div>
    );
};

export default Input;
