import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import Input from "../components/input";
import NavButtons from "../components/nav-buttons";
import { Certificate, useUser } from "../user-context";
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as Edit } from '../assets/svg/edit_square.svg';
import { ReactComponent as Down } from '../assets/svg/keyboard_arrow_down.svg';
import { ReactComponent as Up } from '../assets/svg/keyboard_arrow_up.svg';

const Certificates = () => {
    const { t } = useTranslation();
    const { certificates, setCertificates } = useUser();
    const moveUp = (id: string) => {
        const certificate = certificates.filter(c => c.id === id)[0];
        const index = certificates.indexOf(certificate);
        if (index > 0) {
            const newCertificates = certificates.filter(c => c.id !== id);
            newCertificates.splice(index - 1, 0, certificate);
            setCertificates(newCertificates);
        }
    }

    const moveDown = (id: string) => {
        const certificate = certificates.filter(c => c.id === id)[0];
        const index = certificates.indexOf(certificate);
        if (index < certificates.length - 1) {
            const newCertificates = certificates.filter(c => c.id !== id);
            newCertificates.splice(index + 1, 0, certificate);
            setCertificates(newCertificates);
        }
    }

    const deleteElement = (id: string) => {
        setCertificates(certificates.filter(c => c.id !== id));
    }

    const addCertificate = (id: string | null,
        certificate: string,
        description: string) => {
        if (!id) {
            id = certificate + "_" + Math.random();
            const newCertificate = { id, certificate, description };
            setCertificates([...certificates, newCertificate]);
        } else {
            const newCertificate = { id, certificate, description };
            const newCertificates = [];
            for (let c of certificates) {
                if (c.id != id) {
                    newCertificates.push(c);
                } else {
                    newCertificates.push(newCertificate);
                }
            }
            setCertificates(newCertificates);
        }
    }

    const mapped = certificates.map(c => <LanguageGroup key={c.id} certificate={c} deleteElement={deleteElement} moveDown={moveDown} moveUp={moveUp} addCertificate={addCertificate} />);

    return <div className='w-full max-w-7xl h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10 items-stretch'>
        <div><h1 className='text-xl font-bold'>{t('certificates')}</h1></div>
        <div className="w-full py-4 md:py-8 flex flex-col items-stretch gap-2 md:gap-8">
            {mapped}
        </div>
        <div className="text-lg font-bold">Add New Certificate</div>
        <CertificateForm addCertificate={addCertificate} />
        <NavButtons />
    </div>;
}
export default Certificates;

const LanguageGroup = ({ certificate, moveDown, moveUp, deleteElement, addCertificate }: {
    certificate: Certificate, moveDown: (id: string) => void;
    moveUp: (id: string) => void;
    deleteElement: (id: string) => void;
    addCertificate: (id: string | null,
        certificate: string,
        description: string) => void;
}) => {
    const [show, setShow] = React.useState(false);

    return <div className="border-b border-slate-300 py-2">
        {!show && <div key={certificate.id} className="flex flex-row items-start justify-between">
            <div className="flex flex-col items-start">
                <div className="text-lg flex items-center font-bold">{certificate.certificate}</div>
                <div className="text-sm font-normal">{certificate.description}</div>
            </div>
            <div className="flex flex-row items-center gap-3">
                <button onClick={() => setShow(true)}><Edit className='w-6 h-6 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveDown(certificate.id)}><Down className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveUp(certificate.id)}><Up className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => deleteElement(certificate.id)}><Close className='w-8 h-8 fill-slate-400 hover:fill-slate-600' /></button>
            </div>
        </div>}
        {show && <CertificateForm certificate={certificate} addCertificate={addCertificate} cancel={() => setShow(false)} />}
    </div>
}


const CertificateForm = ({ addCertificate, certificate, cancel }: {
    certificate?: Certificate;
    cancel?: () => void;
    addCertificate: (id: string | null,
        certificate: string,
        description: string) => void
}) => {
    const [certificateName, setCertificateName] = useState<string>(certificate?.certificate || "");
    const [description, setDescription] = useState<string>(certificate?.description || "");

    const onDelete = () => {
        setCertificateName(certificate?.certificate || "");
        setDescription(certificate?.description || "");
    }

    return <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-w-7xl border-tsDarkBlue">
        <Input className="grow" label="Certificate" value={certificateName} onChange={(e) => setCertificateName(e.target.value)} />        
        <Input className="grow col-span-full" label="Certificate Description" value={description} onChange={(e) => setDescription(e.target.value)} />
        <div></div>
        <div className="flex flex-row items-center gap-10">
            {certificate ? <Button onClick={cancel} className="grow" variant="secondary">Cancel</Button> :
                <Button onClick={onDelete} className="grow" variant="secondary">Delete</Button>}
            <Button className="grow" onClick={() => {
                addCertificate(certificate?.id || null, certificateName, description);
                onDelete();
                cancel && cancel();
            }}>Save</Button>
        </div>
    </div>
}