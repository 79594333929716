import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate, useSearchParams } from 'react-router-dom';
import SideMenu from './layout/side-menu';
import { UserProvider } from './user-context';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import PAGES from './pages';
import PreviewDialog from './components/preview-dialog';
import DrawerMenu from './layout/drawer-menu';
import { IoMdMenu } from "react-icons/io";

function getWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
}

function App() {
    const [isSideBarOpen, setSideBarOpen] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(getWidth() < 1024);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(getWidth() < 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (document.location.origin != "http://localhost:3006" && document.location.origin != "https://fastresumecreator.com" && document.location.origin != "https://app.fastresumecreator.com") {
        return <div></div>;
    }

    const routes = PAGES.map(page => <Route key={page.route} path={page.route} element={page.component} />)
    const toggleOpen = () => setSideBarOpen(!isSideBarOpen);
    const closeDrawer = () => setSideBarOpen(false);
    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <UserProvider>
                    <div className='w-screen h-screen flex flex-row items-start pt-3 lg:pt-0'>
                        {/* <SideMenu pages={PAGES} /> */}

                        {isMobile ? <>
                            <button className='fixed bottom-0 bg-white border-tsGreen border-r-2 border-b-2 border-t-2 z-10' onClick={toggleOpen}><IoMdMenu className='w-12 h-12 fill-slate-600' /></button>
                            <DrawerMenu pages={PAGES} isOpen={isSideBarOpen} toggleDrawer={toggleOpen} closeDrawer={closeDrawer} />                            
                        </>
                            : <SideMenu pages={PAGES} />}
                        <div className='w-full h-full grow overflow-auto'>
                            <div className='w-full max-w-7xl flex items-center justify-center text-lg uppercase text-slate-600 pt-1 lg:pt-8'></div>
                            <Routes>
                                {routes}
                                <Route path="/" element={<Navigate to={"/basic"} />} />
                            </Routes>
                        </div>
                        <PreviewDialog ></PreviewDialog>
                    </div>
                </UserProvider>
            </Router>
        </I18nextProvider>
    );
}

export default App;