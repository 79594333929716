import React, { TextareaHTMLAttributes } from 'react';

type Size = "none" | "short" | "medium" | "large" | "full";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    inputSize?: Size;
}

const SIZES: { [key: string]: string } = {
    "none": "",
    "short": "h-16",
    "medium": "h-32",
    "large": "h-48",
    "full": "h-full",
}

const WRAPPER_SIZES: { [key: string]: string } = {
    "none": "",
    "short": "max-w-xs",
    "medium": "max-w-md",
    "large": "max-w-lg",
    "full": "",
}

const TextArea: React.FC<TextAreaProps> = ({ label, inputSize, className, ...textareaProps }) => {
    inputSize = inputSize ? inputSize : "none";
    className = className || "";

    const wrapperClasses = WRAPPER_SIZES[inputSize];
    const textareaClasses = SIZES[inputSize];

    return (
        <div className={`flex flex-col mb-4 ${wrapperClasses} ${className}`}>
            <label className="mb-1">{label}</label>
            <textarea
                {...textareaProps}
                rows={8}
                className={`py-2 px-4 w-full border border-slate-500 rounded-lg focus:outline-none focus:ring focus:border-tsBlue resize-none ${textareaClasses} ${className}`}
            />
        </div>
    );
};

export default TextArea;
