import React from 'react';
import Input from '../components/input';
import { useUser } from "../user-context";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from 'react-i18next';
import Avatar from '../components/avatar';
import NavButtons from '../components/nav-buttons';
import EditorArea from '../components/editor';
import TextArea from '../components/textarea';
import { useSearchParams } from 'react-router-dom';
import TEMPLATES from '../templates/templates';

const BasicInfo = () => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        town,
        address,
        introInformation,
        website,
        git,
        linkedin,
        desiredPosition,
        templateId,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setCountry,
        setTown,
        setAddress,
        setIntroInformation,
        setWebsite,
        setGit,
        setLinkedin,
        setDesiredPosition,
        setTemplateId,
    } = useUser();
    const { t } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    const param = searchParams.get("template");
    if(param?.length && param[0] ) {
        const id = parseInt(param);
        if(templateId !== id) {
            const templates = TEMPLATES.filter((t) => t.id == id);
            if(templates.length > 0) {
                setTemplateId(id);
            }
        }
    }

    return (
        <div className='w-full h-full flex flex-col px-4 lg:px-20 lg:py-10'>
            <div><h1 className='text-xl font-bold'>{t('basic_info')}</h1></div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2 xl:gap-x-8 gap-y-2 max-w-7xl border-tsDarkBlue">
                <div className='flex items-center justify-center col-span-full pb-3'>
                    <Avatar />
                </div>
                <Input label={t("first_name")} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Input label={t("last_name")} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <Input label={t("email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                <Input label={t("phone")} value={phone} onChange={(e) => setPhone(e.target.value)} />
                <Input label={t("country")} value={country} onChange={(e) => setCountry(e.target.value)} />
                <Input label={t("town")} value={town} onChange={(e) => setTown(e.target.value)} />
                <Input label={t("website")} value={website} onChange={(e) => setWebsite(e.target.value)} />
                <Input label={t("linkedin")} value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                <Input label={t("github")} value={git} onChange={(e) => setGit(e.target.value)} />
                <Input label={t("desired_position")} value={desiredPosition} onChange={(e) => setDesiredPosition(e.target.value)} />
                {/* <Input className="col-span-full" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} /> */}
                <div className='col-span-full '>
                    <TextArea label={"Professional Summary/Objective"} value={introInformation} onChange={(e) => setIntroInformation(e.target.value)} />
                </div>
            </div>
            <NavButtons />
        </div>
    );
};

export default BasicInfo;
