import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconLink, IconLinkedin, IconLocationDot } from '../icons';
import { FormatDate } from '../format-date';

Font.register({ family: 'Roboto', src: '/fonts/Roboto/Roboto-Regular.ttf' });
Font.register({ family: 'RobotoBold', src: '/fonts/Roboto/Roboto-Bold.ttf' });
Font.register({ family: 'RobotoMedium', src: '/fonts/Roboto/Roboto-Medium.ttf' });
Font.register({ family: 'RobotoLight', src: '/fonts/Roboto/Roboto-Light.ttf' });
Font.register({ family: 'RobotoThin', src: '/fonts/Roboto/Roboto-Thin.ttf' });

const color = '#000000';
const mainTextColor = '#333333';
const secondaryColor = '#627180';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 11,
        color: mainTextColor,
        width: '100%'
    },
    section: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    sectionNoBorder: {
        marginBottom: 20,
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingBottom: 32,
        display: 'flex',
        gap: 8,
        width: '100%'
    },
    sectionTitle: {
        fontSize: '9px',
        color: secondaryColor,
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        letterSpacing: 1.5,
        marginBottom: 8,
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '62%'
    },
    sidebar: {
        width: '32%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    name: {
        fontSize: '22px',
        color: color,
        fontFamily: 'RobotoMedium',
        marginTop: 3,
    },
    sidebarInner: {
        width: '100%',
        height: '100%'
    },
    desired: {
        fontSize: '15px',
        color: color,
        fontFamily: 'Roboto',
    },
    email: {
        fontSize: '11px',
        textAlign: 'center'
    },
    phone: {
        fontSize: '11px',
        textAlign: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        paddingBottom: 10
    },
    image: {
        width: '70px',
        height: 'auto',
        borderRadius: '50%',
    },
    title: {
        color: color,
        fontFamily: 'RobotoMedium',
        fontSize: 12
    },
    emphasize: {
        fontSize: 11,
        color: color,
    },
    date: {
        color: secondaryColor,
        fontFamily: 'Roboto',
        fontSize: 9
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    link: {
        color: mainTextColor,
        textDecoration: 'none',
    },
    contactsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        paddingTop: 6,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    skill: {
        fontSize: '12px',
        color: mainTextColor,
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
    },
    language: {
        fontSize: '12px',
        color: mainTextColor,
    },
    languageLevel: {
        fontStyle: '8px',
        color: secondaryColor
    },
    personal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingBottom: 8,
        gap: 6
    },
    logo: {
        position: 'absolute',
        bottom: 1,
        right: 1,
        fontSize: 8
    }
});

// Simple
const Template006 = ({ value }: { value: UserContextType }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        desiredPosition,
        skills,
        languages,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
    } = value;
    const formattedWorks = works.map(work => {
        return <View style={styles.work}>
            <Text style={styles.title}>{work.jobTitle}</Text>
            <Text style={styles.emphasize}>{work.employer}</Text>
            <Text style={styles.date}>{FormatDate(work.start)} - {FormatDate(work.end)}</Text>
            <Text>{work.details}</Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        return <View key={educations.id} style={styles.work}>
            <Text style={styles.emphasize}>{educations.degree} in {educations.fieldOfStudy}</Text>
            <Text style={styles.emphasize}>{educations.school}</Text>
            <Text style={styles.date}>{FormatDate(educations.start)} - {FormatDate(educations.end)}</Text>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
            <Text style={styles.languageLevel}>({language.level})</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View style={styles.main}>
                    <View style={styles.content}>
                        <View style={styles.topSection}>
                            <Text style={styles.name}>{firstName} {lastName}</Text>
                            <Text style={styles.desired}>{desiredPosition}</Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Employment History</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                    </View>
                    <View style={styles.sidebar}>
                        {imageSrc && <View style={styles.section}><Image style={styles.image} src={imageSrc as string} /></View>}
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Personal Details</Text>
                            <View style={styles.personal}>
                                <Text style={styles.email}>{email}</Text>
                                <Text style={styles.phone}>{phone}</Text>
                                <Text style={styles.phone} >{country}, {town}</Text>
                                {website && <View style={styles.social}><Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                                {linkedin && <View style={styles.social}><Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                                {git && <View style={styles.social}><Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                            </View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Skills</Text>
                            <View style={styles.skills}>
                                {formattedSkills}
                            </View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Languages</Text>
                            <View style={styles.col}>
                                {formattedLanguages}
                            </View>
                        </View>
                        <View style={styles.sectionNoBorder}>
                            <Text style={styles.sectionTitle}>Education</Text>
                            <View style={styles.work}>{formattedEdu}</View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Template006;
