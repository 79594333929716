import { Dialog, Transition } from '@headlessui/react'
import { Fragment, PropsWithChildren, useState } from 'react'
import PDFCVGenerator from './pdf-cv-generator'
import { ReactComponent as Close } from '../assets/svg/close.svg';
import Button from './button';
import { useUser } from '../user-context';
import PDFBlobPreview from './pdf-blob-preview';

export default function PreviewDialog() {
    const { previewOpen, setPreviewOpen, downloadCV } = useUser();
    const [logoVisible, setLogoVisible] = useState(true);

    function closeModal() {
        setPreviewOpen(false)
    }

    function openModal() {
        setPreviewOpen(true)
    }

    return (
        <>
            <Transition appear show={previewOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-7xl h-[99vh] transform overflow-hidden rounded-2xl bg-slate-200 p-4 text-left align-middle shadow-xl transition-all flex flex-col items-stretch">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between w-full"
                                    >
                                        Preview CV <button onClick={closeModal}><Close className='w-8 h-8 fill-slate-400 hover:fill-slate-600' /></button>
                                    </Dialog.Title>
                                    <div className='p-2 grow'>
                                        <PDFBlobPreview />
                                    </div>
                                    <div className="mt-4 flex flex-row items-center justify-between w-full px-2">
                                        <Button variant='secondary' onClick={closeModal}>
                                            Cancel
                                        </Button>
                                        <Button onClick={downloadCV}>
                                            Download CV
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
