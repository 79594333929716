import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { CiLinkedin } from "react-icons/ci";
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconGithub, IconLink, IconLinkedin, IconLinkedin2, IconLocationDot, IconMail, IconPhone } from '../icons';
import { FormatDate } from '../format-date';

const color = '#00296b';
const colorSecondary = '#222222';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 10,
        gap: 4,
        color: '#444444',
        width: '100%'
    },
    section: {
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        paddingBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        width: '100%'
    },
    contactsSection: {
        paddingBottom: 10,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 8,
        flexWrap: 'wrap',
        width: '100%'
    },
    sectionTitle: {
        fontSize: 16,
        color: color,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 12
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '73%',
        wordWrap: 'break-word'
    },
    sidebar: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    name: {
        fontSize: '26px',
        fontFamily: 'Helvetica-Bold',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        color: color,
    },
    top: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 10,
        width: '100%',
    },
    email: {
        fontSize: '10px',
        textAlign: 'left'
    },
    phone: {
        fontSize: '10px',
        textAlign: 'left'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        width: '100%',
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
    },
    imageView: {
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        width: '70px',
        height: 'auto',
        borderRadius: '50%',
    },
    title: {
        color: colorSecondary,
        fontFamily: 'Helvetica-Bold',
        fontSize: 15
    },
    emphasize: {
        fontSize: 11,
        color: '#111111'
    },
    date: {
        color: '#666666',
        fontSize: 10
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
    },
    link: {
        fontSize: 10,
        textDecoration: 'none',
        color: '#444444',
    },
    contacts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 4,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    skill: {
        fontSize: '12px',
        color: colorSecondary,
        padding: 2,
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
        // justifyContent: 'space-between',
    },
    language: {
        fontSize: '12px',
        color: colorSecondary,
        padding: 2,
    },
    languageLevel: {
        fontSize: '9px'
    },
    desired: {
        color: colorSecondary,
        fontSize: '16px',
        fontFamily: 'Helvetica-Bold',
    },
});

const Template007 = ({ value }: { value: UserContextType, logo?: boolean }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        skills,
        languages,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
        desiredPosition,
    } = value;
    const formattedWorks = works.map(work => {
        return <View style={styles.work}>
            <Text style={styles.title}>{work.jobTitle}</Text>
            <Text style={styles.emphasize}>{work.employer}</Text>
            <Text style={styles.date}>{FormatDate(work.start)} - {FormatDate(work.end)}</Text>
            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        return <View key={educations.id} style={styles.work}>
            <View style={styles.col}>
                <Text style={styles.title}>{educations.degree} in {educations.fieldOfStudy}</Text>
                <Text style={styles.emphasize}>{educations.school}</Text>
                <Text style={styles.date}>{FormatDate(educations.start)} - {FormatDate(educations.end)}</Text>
            </View>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
            <Text style={styles.languageLevel}>{language.level}</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View style={styles.topSection}>
                    {imageSrc && <View style={styles.imageView}><Image style={styles.image} src={imageSrc as string} /></View>}
                    <View style={styles.top}>
                        <Text style={styles.name}>{firstName} {lastName}</Text>
                        <Text style={styles.desired}>{desiredPosition}</Text>
                        <View style={styles.contactsSection}>
                            {email && <View style={styles.social}><IconMail fill='#888888' /><Text style={styles.email}>{email}</Text></View>}
                            {phone && <View style={styles.social}><IconPhone fill='#888888' /><Text style={styles.phone}>{phone}</Text></View>}
                            {website && <View style={styles.social}><IconLink fill='#888888' /> <Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                            {linkedin && <View style={styles.social}><IconLinkedin2 fill='#888888' /> <Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                            {git && <View style={styles.social}><IconGithub fill='#888888' /> <Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                            <View style={styles.social}><IconLocationDot fill='#888888' /> <Text style={styles.link} >{country}, {town}</Text></View>
                        </View>
                    </View>
                </View>

                <View style={styles.main}>
                    <View style={styles.sidebar}>
                        <View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Skills</Text>
                                <View style={styles.skills}>
                                    {formattedSkills}
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Languages</Text>
                                <View style={styles.skills}>
                                    {formattedLanguages}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Work Experience</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Education</Text>
                            <View style={styles.work}>{formattedEdu}</View>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    );
};

export default Template007;

