import React, { useState } from 'react';
import { ReactComponent as AddPhoto } from '../assets/svg/add_a_photo.svg';
import { useUser } from '../user-context';
import { ReactComponent as Close } from "../assets/svg/close.svg";

const Avatar: React.FC = () => {
    const {imageSrc, setImageSrc} = useUser();

    const handleImageClick = () => {
        // Trigger file input click when the image is clicked
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setImageSrc(reader.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const deleteImage = () => {
        setImageSrc(null);
    }

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    return (
        <div className='relative pt-4'>
            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            {imageSrc ? (
                <>
                <img
                    src={imageSrc}
                    alt="Uploaded"
                    className='w-32 h-auto lg:w-48 max-w-[200px] max-h-[200px]'
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                    onClick={handleImageClick}
                />
                <button onClick={deleteImage} className='absolute top-0 right-0 bg-slate-100 bg-opacity-60 p-1 hover:bg-opacity-80 border border-slate-200'><Close className="w-8 h-8 fill-slate-400 hover:fill-slate-600" /></button>
                </>
            ) : (
                <div
                    style={{
                        width: '200px',
                        height: '200px',
                        border: '1px dashed #ccc',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    className="flex flex-col items-center justify-center gap-2"
                    onClick={handleImageClick}
                >
                    <AddPhoto className='w-12 h-12 lg:w-20 lg:h-20 fill-slate-400' />
                    <div>Click to upload image</div>
                </div>
            )}
        </div>
    );
};

export default Avatar;
