import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconLink, IconLinkedin, IconLocationDot } from '../icons';
import { FormatDate } from '../format-date';

Font.register({ family: 'Roboto', src: '/fonts/Roboto/Roboto-Regular.ttf' });
Font.register({ family: 'RobotoBold', src: '/fonts/Roboto/Roboto-Bold.ttf' });
Font.register({ family: 'RobotoMedium', src: '/fonts/Roboto/Roboto-Medium.ttf' });

const color = '#780000';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 11,
        color: '#444444',
        width: '100%'
    },
    section: {
        margin: 2,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        paddingBottom: 15,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%'
    },
    topSectionContent: {
        
    },
    sectionTitle: {
        fontSize: '14px',
        color: '#555555',
        fontFamily: 'RobotoMedium',
        textTransform: 'uppercase',
        borderBottom: '2px solid #888888',
        marginBottom: 10
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        wordWrap: 'break-word'
    },
    sidebar: {
        width: '26%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start'
    },
    name: {
        fontSize: '20px',
        color: color,
        fontFamily: 'RobotoMedium',
        textAlign: 'center'
    },
    email: {
        fontSize: '15px',
        textAlign: 'center'
    },
    phone: {
        fontSize: '15px',
        textAlign: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        width: '100%',
        paddingBottom: 20,
        gap: 3
    },
    image: {
        width: '80px',
        height: 'auto'
    },
    title: {
        color: color,
        fontSize: 15
    },
    emphasize: {
        fontSize: 11,
        fontFamily: 'RobotoMedium',
        color: '#333333'
    },
    date: {
        color: '#666666',
        fontSize: 9
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    link: {
        color: '#666666',
        fontSize: 10,
        textDecoration: 'none',
    },
    contactsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        paddingTop: 8,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        flexWrap: 'wrap',
    },
    skill: {
        fontSize: '12px',
        color: color,
        padding: 2,
        borderBottom: '2px solid #888888',
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'space-between',
    },
    language: {
        fontSize: '12px',
        color: color,
        padding: 2,
        borderBottom: '2px solid #888888',
    },
    languageLevel: {

    }
});

const Template001 = ({ value }: { value: UserContextType }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        skills,
        languages,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
    } = value;
    const formattedWorks = works.map(work => {
        return <View style={styles.work}>
            <Text style={styles.title}>{work.jobTitle}</Text>
            <View style={styles.row}>
                <Text style={styles.emphasize}>{work.employer}</Text>
                <Text style={styles.date}>{FormatDate(work.start)} - {FormatDate(work.end)}</Text>
            </View>

            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        return <View key={educations.id} style={styles.work}>
            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.title}>{educations.degree} in {educations.fieldOfStudy}</Text>
                    <Text style={styles.emphasize}>{educations.school}</Text>
                </View>
                <Text style={styles.date}>{FormatDate(educations.start)} - {FormatDate(educations.end)}</Text></View>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
            <Text style={styles.languageLevel}>{language.level}</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                
                <View style={styles.topSection}>
                    <View style={styles.topSectionContent}>
                        <Text style={styles.name}>{firstName} {lastName}</Text>
                        <Text style={styles.email}>{email}</Text>
                        <Text style={styles.phone}>{phone}</Text>
                        <View style={styles.contactsRow}>
                            {website && <View style={styles.social}><IconLink fill='#666666' /> <Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                            {linkedin && <View style={styles.social}><IconLinkedin fill='#666666' /> <Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                            {git && <View style={styles.social}><IconBxGitRepoForked fill='#666666' /> <Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                            <View style={styles.social}><IconLocationDot fill='#888888' /> <Text style={styles.link} >{country}, {town}</Text></View>
                        </View>
                    </View>
                    {imageSrc && <Image style={styles.image} src={imageSrc as string} />}
                </View>
                <View style={styles.main}>
                    <View style={styles.content}>
                        <View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Summary</Text>
                            <Text>{introInformation}</Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Employment History</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Education</Text>
                            <View style={styles.work}>{formattedEdu}</View>
                        </View>
                        </View>
                    </View>
                    <View style={styles.sidebar}>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Skills</Text>
                                <View style={styles.skills}>
                                    {formattedSkills}
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Languages</Text>
                                <View style={styles.col}>
                                    {formattedLanguages}
                                </View>
                            </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Template001;
