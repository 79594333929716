import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/button";
import Input from "../components/input";
import NavButtons from "../components/nav-buttons";
import { SkillUnit, useUser } from "../user-context";
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as Edit } from '../assets/svg/edit_square.svg';
import { ReactComponent as Down } from '../assets/svg/keyboard_arrow_down.svg';
import { ReactComponent as Up } from '../assets/svg/keyboard_arrow_up.svg';
import Select from "../components/select";

const Skills = () => {
    const { t } = useTranslation();
    const { skills, setSkills } = useUser();

    const moveUp = (id: string) => {
        const skill = skills.filter(skill => skill.id === id)[0];
        const index = skills.indexOf(skill);
        if (index > 0) {
            const newSkills = skills.filter(skill => skill.id !== id);
            newSkills.splice(index - 1, 0, skill);
            setSkills(newSkills);
        }
    }

    const moveDown = (id: string) => {
        const skill = skills.filter(skill => skill.id === id)[0];
        const index = skills.indexOf(skill);
        if (index < skills.length - 1) {
            const newSkills = skills.filter(skill => skill.id !== id);
            newSkills.splice(index + 1, 0, skill);
            setSkills(newSkills);
        }
    }

    const deleteElement = (id: string) => {
        setSkills(skills.filter(skill => skill.id !== id));
    }

    const addSkill = (id: string | null,
        skill: string,
        level: string) => {
        if (!id) {
            id = skill + "_" + Math.random();
            const newSkill = { id, skill, level };
            setSkills([...skills, newSkill]);
        } else {
            const newSkill = { id, skill, level };
            const nSkills = [];
            for (let skill of skills) {
                if (skill.id != id) {
                    nSkills.push(skill);
                } else {
                    nSkills.push(newSkill);
                }
            }
            setSkills(nSkills);
        }
    }

    const mapped = skills.map(skill => <SkillGroup key={skill.id} skill={skill} deleteElement={deleteElement} moveDown={moveDown} moveUp={moveUp} addSkill={addSkill} />);

    return <div className='w-full max-w-7xl h-full flex flex-col px-4 lg:px-20 py-4 lg:py-10 items-stretch'>
        <div><h1 className='text-xl font-bold'>{t('skills')}</h1></div>
        <div className="w-full py-4 md:py-8 flex flex-col items-stretch gap-2 md:gap-8">
            {mapped}
        </div>
        <div className="text-lg font-bold">Add New Skill</div>
        <SkillForm addSkill={addSkill} />
        <NavButtons />
    </div>;
}
export default Skills;

const SkillGroup = ({ skill, moveDown, moveUp, deleteElement, addSkill }: {
    skill: SkillUnit, moveDown: (id: string) => void;
    moveUp: (id: string) => void;
    deleteElement: (id: string) => void;
    addSkill: (id: string | null,
        skill: string,
        level: string) => void;
}) => {
    const [show, setShow] = React.useState(false);

    return <div className="border-b border-slate-300">
        {!show && <div key={skill.id} className="flex flex-row items-start justify-between">
            <div className="flex flex-col items-start justify-center">
                <div className="text-base flex items-center font-bold">{skill.skill}</div>
                {skill.level && <div className="text-sm font-normal">{skill.level}</div>}
            </div>
            <div className="flex flex-row items-center gap-3">
                <button onClick={() => setShow(true)}><Edit className='w-6 h-6 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveDown(skill.id)}><Down className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => moveUp(skill.id)}><Up className='w-11 h-11 fill-slate-400 hover:fill-slate-600' /></button>
                <button onClick={() => deleteElement(skill.id)}><Close className='w-8 h-8 fill-slate-400 hover:fill-slate-600' /></button>
            </div>
        </div>}
        {show && <SkillForm skill={skill} addSkill={addSkill} cancel={() => setShow(false)} />}
    </div>
}


const SkillForm = ({ addSkill, skill, cancel }: {
    skill?: SkillUnit;
    cancel?: () => void;
    addSkill: (id: string | null,
        skill: string,
        level: string) => void
}) => {
    const [skillName, setSkillName] = useState<string>(skill?.skill || "");
    const [level, setLevel] = useState<string>(skill?.level || "");

    const levelOptions = SKILL_LEVELS.map(el => ({
        value: el,
        label: el // translation
    }));

    const onDelete = () => {
        setSkillName(skill?.skill || "");
        setLevel(skill?.level || "");
    }

    return <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4 max-w-7xl border-tsDarkBlue">
        <Input className="grow" label="Skill" value={skillName} onChange={(e) => setSkillName(e.target.value)} />
        <Select label={"Level"} options={levelOptions} onChange={(v: any) => setLevel(v.value)} defaultValue={skill?.level ? levelOptions.filter(o => o.value == skill?.level)[0] : ""} />
        <div></div>
        <div className="flex flex-row items-center gap-10">
            {skill ? <Button onClick={cancel} className="grow" variant="secondary">Cancel</Button> :
                <Button onClick={onDelete} className="grow" variant="secondary">Delete</Button>}
            <Button className="grow" onClick={() => {
                addSkill(skill?.id || null, skillName, level);
                onDelete();
                cancel && cancel();
            }}>Save</Button>
        </div>
    </div>
}

const SKILL_LEVELS = [
    "beginner",
    "competent",
    "advanced",
    "proficient",
    "expert"
]