import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../components/button";
import { useUser } from "../user-context";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { IoMdMenu } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";

const DrawerMenu = ({ pages, isOpen, toggleDrawer, closeDrawer }: { pages: { name: string, route: string }[], isOpen: boolean; toggleDrawer: () => void; closeDrawer: () => void }) => {
    const location = useLocation();
    const { downloadCV, setPreviewOpen } = useUser();

    useEffect(() => {
        closeDrawer();
    }, [location]);

    if (document.location.origin != "http://localhost:3006" && document.location.origin != "https://fastresumecreator.com" && document.location.origin != "https://app.fastresumecreator.com") {
        throw Error("Error");
    }

    const links = pages.map(page => <Link className={location.pathname === page.route ? "font-bold py-5 w-full flex items-center justify-center" : "py-5 w-full flex items-center justify-center"} key={page.route} to={page.route} >{page.name}</Link>)

    return <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='left'
        className=''
        enableOverlay={true}
        size={300}
        zIndex={10}
        overlayClassName="bg-black"
    >
        <div className="bg-white z-50 w-full h-screen flex flex-col items-center justify-between border-r-2 border-tsGreen">
            <div className="w-full flex flex-col items-center divide-y divide-slate-300 px-10 overflow-y-auto py-10">
                <button className='absolute bottom-0 -right-12 bg-white border-tsGreen border-r-2 border-b-2 border-t-2' onClick={closeDrawer}><AiOutlineClose className='w-12 h-12 fill-slate-600' /></button>
                <a className="uppercase py-5" href="https://fastresumecreator.com">Homepage</a>
                {links}
                <Button className="w-full my-3" onClick={() => setPreviewOpen(true)}>Preview</Button>
                <Button className="w-full my-3" onClick={downloadCV}>Download as PDF</Button>
            </div>
        </div>
        <button>Close</button>
    </Drawer>
}
export default DrawerMenu;