import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Svg, Path, SVGProps, Link } from '@react-pdf/renderer';
import { CiLinkedin } from "react-icons/ci";
import { UserContextType } from '../../user-context';
import FormatUrl from '../../util/format-url';
import { IconBxGitRepoForked, IconGithub, IconLink, IconLinkedin, IconLinkedin2, IconLocationDot, IconMail, IconPhone } from '../icons';
import { FormatDate } from '../format-date';

Font.register({ family: 'OpenSans-Regular', src: '/fonts/Open_Sans/static/OpenSans-Regular.ttf' });
Font.register({ family: 'PT_SerifBold', src: '/fonts/PT_Serif/PTSerif-Bold.ttf' });
Font.register({ family: 'PT_Serif', src: '/fonts/PT_Serif/PTSerif-Regular.ttf' });

const color = '#012a4a';

const mainFont = 'PT_SerifBold';
const secondaryFont = 'PT_Serif'

const styles = StyleSheet.create({
    page: {
        fontFamily: secondaryFont,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
        padding: 40,
        fontSize: 10,
        color: '#444444',
        width: '100%',
        height: '100%',
    },
    section: {
        margin: 2,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    topSection: {
        padding: 10,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        backgroundColor: color,
        color: '#FFFFFF',
        width: '100%'
    },
    sectionTitle: {
        fontSize: '14px',
        fontFamily: mainFont,
        textTransform: 'uppercase',
        marginBottom: 10
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: 20
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '62%',
    },
    sidebar: {
        width: '36%',
        height: 'auto',
        display: 'flex',
        flexGrow: 1,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        backgroundColor: color,
        color: '#FFFFFF'
    },
    name: {
        fontSize: '24px',
        fontFamily: mainFont,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column'
    },
    email: {
        fontSize: '10px',
        textAlign: 'center'
    },
    phone: {
        fontSize: '10px',
        textAlign: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    work: {
        width: '100%',
        paddingBottom: 10,
        gap: 3
    },
    image: {
        width: '80px',
        height: 'auto'
    },
    title: {
        color: '#333333',
        fontFamily: mainFont,
        fontSize: 15
    },
    emphasize: {
        fontSize: 12,
        fontFamily: secondaryFont,
        color: '#333333'
    },
    date: {
        color: '#666666',
        fontSize: 9
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
    },
    link: {
        color: '#ffffff',
        fontSize: 10,
        textDecoration: 'none',
    },
    contacts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 4,
        paddingTop: 8,
        width: '100%'
    },
    skills: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: mainFont,
        gap: 10,
        flexWrap: 'wrap',
    },
    skill: {
        fontSize: '13px',
        fontFamily: secondaryFont,
        padding: 2,
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    language: {
        fontSize: '13px',
        padding: 2,
    },
    languageLevel: {

    }
});

const Template005 = ({ value }: { value: UserContextType, logo?: boolean }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        country,
        linkedin,
        git,
        website,
        skills,
        languages,
        town,
        address,
        introInformation,
        works,
        educations,
        imageSrc,
    } = value;
    const formattedWorks = works.map(work => {
        return <View style={styles.work}>
            <Text style={styles.title}>{work.jobTitle}</Text>
            <View style={styles.col}>
                <Text style={styles.emphasize}>{work.employer}</Text>
                <Text style={styles.date}>{FormatDate(work.start)} - {FormatDate(work.end)}</Text>
            </View>

            <Text>{work.details}</Text>
            <Text></Text>
        </View>
    })

    const formattedEdu = educations.map(educations => {
        return <View key={educations.id} style={styles.work}>
            <View style={styles.col}>
                <View style={styles.col}>
                    <Text style={styles.title}>{educations.degree} in {educations.fieldOfStudy}</Text>
                    <Text style={styles.emphasize}>{educations.school}</Text>
                </View>
                <Text style={styles.date}>{FormatDate(educations.start)} - {FormatDate(educations.end)}</Text></View>
        </View>
    });

    const formattedSkills = skills.map(skill => {
        return <Text style={styles.skill}>{skill.skill}</Text>
    });
    const formattedLanguages = languages.map(language => {
        return <View style={styles.languageContainer}>
            <Text style={styles.language}>{language.language}</Text>
        </View>
    })
    return (
        <Document title='cv.pdf' creator='https://fastresumecreator.com/'>
            <Page size='A4' style={styles.page} wrap={true}>
                <View style={styles.main}>
                    <View style={styles.sidebar}>
                        <View>
                            <View style={styles.topSection}>
                                <View style={styles.name}>
                                    <Text>{firstName}</Text>
                                    <Text>{lastName}</Text>
                                </View>
                                <View style={styles.contacts}>
                                    {email && <View style={styles.social}><IconMail fill='#FFFFFF' /><Text style={styles.email}>{email}</Text></View>}
                                    {phone && <View style={styles.social}><IconPhone fill='#FFFFFF' /><Text style={styles.phone}>{phone}</Text></View>}
                                    {website && <View style={styles.social}><IconLink fill='#FFFFFF' /> <Text><Link style={styles.link} src={website}>{FormatUrl(website)}</Link></Text></View>}
                                    {linkedin && <View style={styles.social}><IconLinkedin2 fill='#FFFFFF' /> <Text><Link style={styles.link} src={linkedin}>linkedin</Link></Text></View>}
                                    {git && <View style={styles.social}><IconGithub fill='#FFFFFF' /> <Text><Link style={styles.link} src={git}>github</Link></Text></View>}
                                    <View style={styles.social}><IconLocationDot fill='#FFFFFF' /> <Text style={styles.link} >{country}, {town}</Text></View>
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text>{introInformation}</Text>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Skills</Text>
                                <View style={styles.skills}>
                                    {formattedSkills}
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.sectionTitle}>Languages</Text>
                                <View style={styles.col}>
                                    {formattedLanguages}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Work Experience</Text>
                            <View style={styles.work}>{formattedWorks}</View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Education</Text>
                            <View style={styles.work}>{formattedEdu}</View>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    );
};

export default Template005;

